export const getDefaultState = () => {
    return {
        tasks: [],

        task: {
            id: null,
            number: null,
            description: null,

        },

        validation: {

            number: null,
            description: null,


        },

        search: {
            id: null,
            client_id: null,
            task_state_id: null,
        }
    }
}

export default getDefaultState()