import state from './appConfigState'
import actions from './appConfigActions'
import mutations from './appConfigMutations'
import getters from './appConfigGetters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
