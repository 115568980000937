import { ROLES_LIST, ROLES_CREATE, ROLES_EDIT } from '../constants'

export default [
	{
		path: ROLES_LIST,
		name: 'roles_list',
		component: () => import('@/views/roles/list/listView.vue'),
		meta: {
			pageTitle: 'Listado Roles',
			permission: 'list_roles',
			breadcrumb: [
				{
					text: 'Listado Roles',
					active: true,
				},
			],
		},
	},
	{
		path: ROLES_CREATE,
		name: 'roles_create',
		component: () => import('@/views/roles/form/formView.vue'),
		meta: {
			pageTitle: 'Crear Rol',
			permission: 'create_roles',
			breadcrumb: [
				{
					text: 'Roles',
                    to: ROLES_LIST,
                },
				{
					text: 'Crear Rol',
					active: true,
				},
			],
		},
	},
	{
		path: `${ROLES_EDIT}/:id`,
		name: 'roles_edit',
		component: () => import('@/views/roles/form/formView.vue'),
		meta: {
			pageTitle: 'Editar Rol',
			permission: 'edit_roles',
			breadcrumb: [
				{
					text: 'Roles',
                    to: ROLES_LIST,
                },
				{
					text: 'Editar Rol',
					active: true,
				},
			],
		},
	},
]
