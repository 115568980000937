import { updateField } from "vuex-map-fields"
import { getDefaultState, getWorkPartDefaultState } from "./workPartState"
import helpers from "@/resources/helpers/helpers"
import {type} from "@/constants/workPart"

export default {
	updateField,
	RESET_WORKPART_STATE(state) {
		Object.assign(state, getWorkPartDefaultState())
	},
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_WORK_PARTS(state, payload) {
		state.workParts = payload
	},
	SET_WORK_PART_DOCUMENTS(state, payload) {
		state.workPartsDocuments = payload
	},
	SET_LIST_MATERIALS(state, payload){
		state.listMaterials = payload
	},
	SET_WORK_PART(state, payload) {
		Object.entries(payload).forEach(([key, value]) => {
			if (key=='work_process')
				state.workPart['workProcess'] = value.map(function (obj) {
					return obj.id
				})
			else
				state.workPart[key] = value
		})
	},
	SET_WORK_PROCESS(state, payload) {
		state.process_1 = payload[type[0]]
		state.process_2 = payload[type[1]]
		state.process_3 = payload[type[2]]
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation) {
			state.validation[key] = null
		}
	},
}

