import {
    EQUIPMENTS_LIST,
    EQUIPMENTS_CLIENTS_LIST,
    EQUIPMENTS_CREATE,
    EQUIPMENTS_VIEW,
    EQUIPMENTS_EDIT, CLIENTS_LIST,
} from '../constants'

export default [
    {
        path: `${EQUIPMENTS_LIST}`,
        name: 'list_equipments',
        component: () => import('@/views/equipments/list/listView.vue'),
        meta: {
            pageTitle: 'Listado Equipos',
            permission: 'list_client_equipments',
            breadcrumb: [
                {
                    text: 'Listado Equipos',
                    active: true,
                },
            ],
        },
    },
    {
        path: `${EQUIPMENTS_CLIENTS_LIST}/:id`,
        name: 'list_client_equipments',
        component: () => import('@/views/equipments/list/listView.vue'),
        meta: {
            pageTitle: 'Listado Equipos de cliente',
            permission: 'list_client_equipments',
            breadcrumb: [
                {
                    text: 'Listado Equipos de cliente',
                    active: true,
                },
            ],
        },
    },
    {
        path: `${EQUIPMENTS_CREATE}`,
        name: 'create_equipments',
        component: () => import('@/views/equipments/form/formView.vue'),
        meta: {
            pageTitle: 'Crear Equipo',
            permission: 'create_equipments',
            breadcrumb: [
                {
                    text: 'Clientes',
                    to: CLIENTS_LIST,
                },
                {
                    pageTitle: 'Crear Equipos',
                    active: true,
                },
            ],
        },
    },
    {
        path: `${EQUIPMENTS_CREATE}/:client`,
        name: 'create_equipments_client',
        component: () => import('@/views/equipments/form/formView.vue'),
        meta: {
            pageTitle: 'Crear Equipo',
            permission: 'create_equipments',
            breadcrumb: [
                {
                    text: 'Clientes',
                    to: CLIENTS_LIST,
                },
                {
                    pageTitle: 'Crear Equipos',
                    active: true,
                },
            ],
        },
    },
    {
        path: `${EQUIPMENTS_VIEW}/:id`,
        name: 'view_equipments',
        component: () => import('@/views/equipments/sheet/sheetView.vue'),
        meta: {
            pageTitle: 'Ficha Equipo',
            permission: 'list_client_equipments',
            breadcrumb: [
                {
                    text: 'Ficha Equipo',
                    active: true,
                },
            ],
        },
    },
    {
        path: `${EQUIPMENTS_EDIT}/:id`,
        name: 'edit_equipments',
        component: () => import('@/views/equipments/form/formView.vue'),
        meta: {
            pageTitle: 'Editar Equipos',
            permission: 'edit_equipments',
            breadcrumb: [
                {
                    text: 'Editar Equipos',
                    active: true,
                },
            ],
        },
    },
    {
        path: `${EQUIPMENTS_EDIT}/:client/:id`,
        name: 'edit_equipments_client',
        component: () => import('@/views/equipments/form/formView.vue'),
        meta: {
            pageTitle: 'Editar Equipos',
            permission: 'edit_equipments',
            breadcrumb: [
                {
                    text: 'Editar Equipos',
                    active: true,
                },
            ],
        },
    }
]
