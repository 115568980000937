import state from './notificationState'
import actions from './notificationActions'
import mutations from './notificationMutations'
import getters from './notificationGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
