import ToastificationContent from "@core/components/toastification/ToastificationContent"
import Vue from "vue"

export default {
    formatDate(data) {
        let date = new Date(data)
        return this.padTo2Digits(date.getDate())  + '-' + this.padTo2Digits((date.getMonth() + 1))  + '-' +  date.getFullYear()
    },
    formatDateHour(data) {
        let date = new Date(data)
        return this.padTo2Digits(date.getDate())  + '-' + this.padTo2Digits((date.getMonth() + 1))  + '-' +  date.getFullYear() + ' ' + this.padTo2Digits(date.getHours()) + ':' + this.padTo2Digits(date.getMinutes())
    },
    formatDateToHour(data) {
        let date = new Date(data)
        return this.padTo2Digits(date.getHours()) + ':' + this.padTo2Digits(date.getMinutes())
    },
    formatDateString(date) {
        const dateFormated = new Date(date)

        return dateFormated.toLocaleDateString("es-ES")
    },
    formatHour(hour) {
        return hour.substring(0,5)
    },
    getYearFromDate(data) {
        let date = new Date(data)
        return date.getFullYear()
    },
    resetVariable(variable){
        for (const key in variable) {
            variable[key] = null
        }
    },
    showToast(title, icon, variant, link){
        Vue.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
                title: title,
                icon: icon,
                variant: variant,
                link: link,
            },
        })
    },
    padTo2Digits(num) {
        return String(num).padStart(2, '0')
    },
    appendFormData(formData, data, rootName) {
        let root = rootName || ''
        if (data instanceof File) {
            formData.append(root, data)
        } else if (Array.isArray(data)) {
            for (var i = 0; i < data.length; i++) {
                this.appendFormData(formData, data[i], root + '[' + i + ']')
            }
        } else if (typeof data === 'object' && data) {
            for (var key in data) {
                if (data.hasOwnProperty(key)) {
                    if (root === '') {
                        this.appendFormData(formData, data[key], key)
                    } else {
                        this.appendFormData(formData, data[key], `${root}[${key}]`)
                    }
                }
            }
        } else {
            if (data !== null && typeof data !== 'undefined') {
                formData.append(root, data)
            } else {
                formData.append(root, '')
            }
        }
    },
}
