import { USER_REGISTER_REQUESTS_LIST, USER_REGISTER_REQUESTS_SHEET } from '../constants'

export default [
	{
		path: USER_REGISTER_REQUESTS_LIST,
		name: 'user_register_requests_list',
		component: () => import('@/views/userRegisterRequests/list/listView.vue'),
		meta: {
			pageTitle: 'Listado peticiones de registro',
			permission: 'list_user_register_requests',
			breadcrumb: [
				{
					text: 'Listado peticiones de registro',
					active: true,
				},
			],
		},
	},
	{
		path: USER_REGISTER_REQUESTS_SHEET,
		name: 'user_register_requests_sheet',
		component: () => import('@/views/userRegisterRequests/sheet/sheetView.vue'),
		meta: {
			pageTitle: 'Editar usuario',
			permission: 'see_user_register_requests',
			breadcrumb: [
				{
					text: 'Editar usuario',
					active: true,
				},
			],
		},
	},
]
