import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./equipmentState"

export default {
    updateField,
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_EQUIPMENT(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            if (key === 'active_tag')
                state.equipment['tag_id'] = value[0].id
            if (key === 'uncatalogued')
                state.equipment['uncatalogued'] = !!value
             else
                state.equipment[key] = value
        })
    },
    SET_EQUIPMENT_TECNICAL_DOCUMENTATION(state, payload) {
        state.equipment.equipment_tecnical_documentation = payload
    },
    SET_EQUIPMENT_CERTIFICATION(state, payload) {
        state.equipment.equipment_certification = payload
    },
    SET_EQUIPMENT_DELIVERY_NOTES(state, payload) {
        state.equipment.equipment_delivery_note = payload
    },
    SET_EQUIPMENT_PREDICTIVE_REPORT(state, payload) {
        state.equipment.equipment_predictive_report = payload
    },
    SET_EQUIPMENT_REPAIR_REPORT(state, payload) {
        state.equipment.equipment_repair_report = payload
    },
    SET_EQUIPMENTS(state, payload) {
        state.equipments = payload
    },
    SET_VALIDATION_ERRORS(state, payload) {
        for (const key in payload) {
            state.validation[key] = '* '+payload[key][0]
        }
    },
    CLEAR_VALIDATION(state) {
        for (const key in state.validation) {
            state.validation[key] = null
        }
    },
}
