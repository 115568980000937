import { CALENDAR } from '../constants'

export default [
    {
        path: CALENDAR,
        name: 'see_calendar',
        component: () => import('@/views/calendar/calendarView.vue'),
        meta: {
            pageTitle: 'Calendario',
            permission: 'see_calendar',
            breadcrumb: [
                {
                    text: 'Calendario',
                    active: true,
                },
            ],
        },
    },
]
