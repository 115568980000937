import state from './verticalMenuState'
import actions from './verticalMenuActions'
import mutations from './verticalMenuMutations'
import getters from './verticalMenuGetters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
