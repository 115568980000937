import api from "@/router/api"

export default {
	searchRoles({commit}, payload){
		return new Promise((resolve, reject) => {
			api.roles.search(payload)
				.then(response => {
					const {data} = response.data
					commit('SET_ROLES', data.data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los usuarios'
					})
				})
		})
	},
	getPermissions({commit}, payload){
		return new Promise((resolve, reject) => {
			api.roles.listPermissions(payload)
				.then(response => {
					const {data} = response.data
					commit('SET_PERMISSIONS', data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los usuarios'
					})
				})
		})
	},
	getRole({commit}, payload){
		return new Promise((resolve, reject) => {
			api.roles.find(payload.id, payload)
				.then(response => {
					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el rol',
					})
				})
		})
	},
	createRole({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.roles.create(payload)
				.then(response => {
					const {data} = response.data
					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	updateRole({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.roles.update(payload)
				.then(response => {
					const {data} = response.data
					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	deleteRole({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.roles.delete(payload.id)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al eliminar el usuario.',
					})
				})
		})
	}
}
