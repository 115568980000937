import state from './loginUserLogState'
import actions from './loginUserLogActions'
import mutations from './loginUserLogMutations'
import getters from './loginUserLogGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
