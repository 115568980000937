import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/sat/roles/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/sat/roles/list`, {params: data})
	},
	listByWorkCenter(data){
		return axios.get(`/sat/roles/list/workcenter`, {params: data})
	},
	listPermissions(data) {
		return axios.get(`/sat/roles/list/permissions`, {params: data})
	},
	create(data) {
		return axios.post(`/sat/roles/create`, data)
	},
	update(data) {
		return axios.post(`/sat/roles/update`, data)
	},
	delete(id) {
		return axios.delete(`/sat/roles/delete/${id}`)
	},
	getRoleNameWorkCenter(data){
		return axios.get(`/sat/roles/find-by-reference`, {params: data})
	}
}