import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/sat/ots/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/sat/ots/list`, {params: data})
	},
	searchClosed(data) {
		return axios.get(`/sat/ots/list/closed`, {params: data})
	},
	assignOperators(id, data) {
		return axios.post(`/sat/ots/assignOperators/${id}`, data)
	},
	create(data) {
		return axios.post(`/sat/ots/create`, data)
	},
	update(id, data) {
		return axios.post(`/sat/ots/update/${id}`, data)
	},
	delete(id) {
		return axios.delete(`/sat/ots/delete/${id}`)
	},
	deleteFile(id) {
		return axios.delete(`/sat/ots/deleteFile/${id}`)
	},
	open(id) {
		return axios.get(`/sat/ots/open/${id}`)
	},
	end(id) {
		return axios.get(`/sat/ots/end/${id}`)
	},
	listAll(data) {
		return axios.get(`/sat/ots/list/all`, {params: data})
	},
	listByClient(data) {
		return axios.get(`/sat/ots/list/client`, {params: data})
	},
	getCalendarOts(data) {
		return axios.post('/sat/ots/list/calendar', data)
	},
	updateDateOtFromCalendar(data) {
		return axios.post('/sat/ots/update/date/calendar', data)
	},
	printPDF(data){
		return axios.post(`/sat/ots/print/${data.id}`, data, {responseType: 'blob'})
	},
	getOts(){
		return axios.get(`/wingest/get-ots`)
	},
}