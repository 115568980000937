import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./rmaState"
import helpers from "@/resources/helpers/helpers"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_RMAS(state, payload) {
		state.rmas = payload
	},
	SET_RMA(state, payload) {
		Object.entries(payload).forEach(([key, value]) => {
			state.rma[key] = value
		})
	},
	SET_RMA_OPERATORS(state, payload){
		state.rma.operators = payload.map(function (value){
			return value.id
		})
	},
	SET_OPERATORS(state, payload){
		state.operators = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation) {
			state.validation[key] = null
		}
	},
}

