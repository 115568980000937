import { USER_LIST, USER_CREATE, USER_EDIT, USER_CLIENT_CREATE, USER_CLIENT_EDIT, USER_CLIENT_LIST, USER_CLIENT_SHEET } from '../constants'

export default [
	{
		path: USER_LIST,
		name: 'users_list',
		component: () => import('@/views/users/list/listView.vue'),
		meta: {
			pageTitle: 'Listado Usuarios',
			permission: 'list_users',
			breadcrumb: [
				{
					text: 'Listado Usuarios',
					active: true,
				},
			],
		},
	},
	{
		path: USER_CREATE,
		name: 'users_create',
		component: () => import('@/views/users/form/formView.vue'),
		meta: {
			pageTitle: 'Crear Usuario',
			permission: 'create_users',
			breadcrumb: [
				{
					text: 'Usuarios',
                    to: USER_LIST,
                },
				{
					text: 'Crear Usuario',
					active: true,
				},
			],
		},
	},
	
	{
		path: `${USER_EDIT}/:id`,
		name: 'users_edit',
		component: () => import('@/views/users/form/formView.vue'),
		meta: {
			pageTitle: 'Editar Usuario',
			permission: 'edit_users',
			breadcrumb: [
				{
					text: 'Usuarios',
                    to: USER_LIST,
                },
				{
					text: 'Editar Usuario',
					active: true,
				},
			],
		},
	},
	{
		path: `${USER_CLIENT_LIST}/:id`,
		name: 'users_client_list',
		component: () => import('@/views/users/client/list/listView.vue'),
		meta: {
			pageTitle: 'Listado de Usuarios de Cliente',
			permission: 'list_users_client',
			breadcrumb: [
				{
					text: 'Usuarios',
					to: USER_LIST,
				},
			],
		},
	},
	{
		path: `${USER_CLIENT_CREATE}/:client?`,
		name: 'users_client_create',
		component: () => import('@/views/users/client/form/formView.vue'),
		meta: {
			pageTitle: 'Crear Usuario de Cliente',
			permission: 'create_users',
			breadcrumb: [
				{
					text: 'Usuarios',
					to: USER_LIST,
				},
				{
					text: 'Crear Usuario',
					active: true,
				},
			],
		},
	},
	{
		path: `${USER_CLIENT_EDIT}/:id`,
		name: 'users_client_edit',
		component: () => import('@/views/users/client/form/formView.vue'),
		meta: {
			pageTitle: 'Editar Usuario de Cliente',
			permission: 'edit_users',
			breadcrumb: [
				{
					text: 'Usuarios',
					to: USER_LIST,
				},
				{
					text: 'Editar Usuario',
					active: true,
				},
			],
		},
	},
	{
		path: `${USER_CLIENT_SHEET}/:id`,
		name: 'users_client_ver',
		component: () => import('@/views/users/client/sheet/sheetView.vue'),
		meta: {
			pageTitle: 'Ver Usuario de Cliente',
			permission: 'see_users',
			breadcrumb: [
				{
					text: 'Usuarios',
					to: USER_LIST,
				},
				{
					text: 'Ver Usuario',
					active: true,
				},
			],
		},
	},

	
]
