import state from './equipmentState'
import actions from './equipmentActions'
import mutations from './equipmentMutations'
import getters from './equipmentGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
