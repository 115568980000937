import api from "@/router/api"

export default {
    searchOts({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.ots.search(payload)
                .then(response => {
                    const { data } = response.data
                    commit('SET_OTS', data.data)
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los ots'
                    })
                })
        })
    },
    searchClosedOts({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.ots.searchClosed(payload)
                .then(response => {
                    const { data } = response.data
                    commit('SET_OTS', data.data)
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los ots'
                    })
                })
        })
    },
    getOt({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.ots.find(payload.id, payload)
                .then(response => {
                    commit('SET_OT', response.data.data)

                    if (response.data.data.operators) {
                        commit('SET_OT_OPERATORS', response.data.data.operators)
                        commit('SET_OPERATORS', response.data.data.operators)
                    }

                    resolve(response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener el ot',
                    })
                })
        })
    },
    getAllOts({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.ots.listAll(payload)
                .then(response => {
                    const { data } = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los ots.',
                    })
                })
        })
    },
    getOtsByClient({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.ots.listByClient(payload)
                .then(response => {
                    const { data } = response.data
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los ots'
                    })
                })
        })
    },
    assignOperators({commit}, payload){
        return new Promise((resolve, reject) => {
            api.ots.assignOperators(payload.id, payload)
                .then(response => {
                    commit('SET_OT', response.data.data)
                    resolve(response.data.data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    if(status === 422){
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }

                    reject({title, status})
                })
        })
    },
    createOt({ commit }, payload) {
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.ots.create(payload)
                .then(response => {
                    const { data } = response.data
                    commit('SET_OT', data)

                    resolve(data)
                })
                .catch(error => {

                    const { data, status } = error.response
                    const { message: title } = data

                    if (status === 422) {
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({ title, status })
                })
        })
    },
    updateOt({ commit }, payload) {
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.ots.update(payload.id, payload)
                .then(response => {
                    const { data } = response.data
                    commit('SET_OT', data)

                    resolve(data)
                })
                .catch(error => {
                    const { data, status } = error.response
                    const { message: title } = data

                    if (status === 422) {
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({ title, status })
                })
        })
    },
    openOt({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.ots.open(payload.id)
                .then(response => {

                    const { data } = response.data
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los ots'
                    })
                })
        })
    },
    endOt({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.ots.end(payload.id)
                .then(response => {

                    const { data } = response.data
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los ots'
                    })
                })
        })
    },
    deleteOt({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.ots.delete(payload.id)
                .then(response => {

                    const { data } = response.data
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los ots'
                    })
                })
        })
    },
    deleteFile({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.ots.deleteFile(payload.id)
                .then(response => {
                    const { data } = response.data
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al borrar el archivo'
                    })
                })
        })
    },
    getCalendarOts({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.ots.getCalendarOts(payload)
                .then(response => {
                    const { data } = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los ots'
                    })
                })
        })
    },
    printPDF({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.ots.printPDF(payload)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    const { data: { message } } = error.response
                    reject({
                        message
                    })
                })
        })
    },
}