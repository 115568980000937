import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./loginUserLogState"
import helpers from "@/resources/helpers/helpers"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_LOGIN_USER_LOGS(state, payload) {
		state.loginUserLogs = payload
	},
}
