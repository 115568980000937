export const getDefaultState = () => {
    return {
        ots: [],
        operators: [],

        ot: {
            id: null,
            date_start: null,
            date_end: null,
            description: null,
            model: null,

            ot_id: null,
            work_center_id: null,
            client_id: null,
            ot_state_id: null,
            files: [],

            operators: [],
            client: {
                name: null
            },
            state: {
                name: null
            }
        },

        validation: {
            date_start: null,
            date_end: null,
            description: null,
            model: null,

            ot_id: null,
            work_center_id: null,
            client_id: null,
            ot_state_id: null,

            operators: null,
        },

        search: {
            id: null,
            date_end: null,
            operators: null,
            client_id: null,
            ot_state_id: null,
        }
    }
}

export default getDefaultState()
