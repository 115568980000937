import {OTS_LIST, OT_VIEW, OT_CREATE, OT_EDIT, OTS_CONTROL} from '../constants'
import otWorkParts from './otsWorkParts.js'

export default [
	{
		path: OTS_LIST,
		name: 'ots_list',
		component: () => import('@/views/ots/list/listView.vue'),
		meta: {
			pageTitle: 'Listado OTs',
			permission: 'list_ots',
			breadcrumb: [
				{
					text: 'Listado OTs',
					active: true,
				},
			],
		},
	},
	{
		path: OTS_CONTROL,
		name: 'ots_control',
		component: () => import('@/views/ots/control/listView.vue'),
		meta: {
			pageTitle: 'Control OTs',
			permission: 'see_control_ot',
			breadcrumb: [
				{
					text: 'Control OTs',
					active: true,
				},
			],
		},
	},
	{
		path: `${OT_VIEW}/:id`,
		name: 'ots_view',
		component: () => import('@/views/ots/sheet/sheetView.vue'),
		meta: {
			pageTitle: 'Ver OT',
			permission: 'see_ot',
			breadcrumb: [
				{
					text: 'OTs',
					to: OTS_LIST,
				},
				{
					text: 'Ver OT',
					active: true,
				},
			],
		},
	},
	...otWorkParts	
]
