import authService from "@/services/auth/authService"

const userInfoLocalStorage = authService.user() || {}

const getUserInfo = () => {
    const userInfo = {}

    Object.keys(userInfoLocalStorage).forEach(key => {
        if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null) userInfo[key] = userInfoLocalStorage[key]
    })

    return userInfo
}

const state = {
    AppActiveUser: getUserInfo(),
    workCenter: {
        name: null
    },
    pageTitle: null,
}

export default state
