export default {
	SET_ROLES(state, payload) {
		state.roles = payload
	},
	SET_PERMISSIONS(state, payload) {
		state.permissions = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation) {
			state.validation[key] = null
		}
	},
}
