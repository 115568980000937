import {OT_WORK_PART_LIST} from '../constants'

export default [
	{
		path: `${OT_WORK_PART_LIST}/:ot_id`,
		name: 'ots_work_part_list',
		component: () => import('@/views/workPart/list/listView.vue'),
		meta: {
			pageTitle: 'Partes de trabajo de ot',
			permission: 'list_work_part_ot',		
			breadcrumb: [
				{
					text: 'Partes de trabajo',
					active: true,
				},
			],
		},
	},
]
