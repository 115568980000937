import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./clientState"
import helpers from "@/resources/helpers/helpers"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_CLIENTS(state, payload) {
		state.clients = payload
	},
	SET_CLIENT(state, payload) {

		Object.entries(payload).forEach(([key, value]) => {

			if (key != 'client_data' && key != 'client_filter_data'){
				state.client[key] = value
			}
		})
	},
	SET_VALIDATION_ERRORS(state, payload) {

		for (const key in payload) {
		
			state.validation[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation) {
			state.validation[key] = null
		}
	},
}

