import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./interventionState"

export default {
    updateField,
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_INTERVENTION(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.intervention[key] = value
        })
    },
    SET_INTERVENTIONS(state, payload) {
        state.interventions = payload
    },
}
