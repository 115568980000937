export const getDefaultState = () => {
    return {
        interventions: [],
        intervention: {
            id: null,
            tag_id: null,
            equipment_id: null,
            description: null,
            intervention_states_id: null,
            equipment: {},
            tag: {},
            user: {},
            intervention_photo_file: null,
            logs: {},
        },
        validation: {
            tag_id: null,
            equipment_id: null,
            description: null,
            equipment: {},
            tag: {},
            user: {},
            intervention_photo_file: null,
        },
    }
}

export default getDefaultState()
