import state from './clientState'
import actions from './clientActions'
import mutations from './clientMutations'
import getters from './clientGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
