import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/sat/tags/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/sat/tags/list`, {params: data})
	},
	create(data) {
		return axios.post(`/sat/tags/create`, data)
	},
	update(data) {
		return axios.post(`/sat/tags/update`, data)
	},
	delete(id) {
		return axios.delete(`/sat/tags/delete/${id}`)
	},
	listAll(data) {
		return axios.get(`/sat/tags/list/all`, {params: data})
	},
	listByWorkcenter(data) {
		return axios.get(`/sat/tags/list/workcenter`, {params: data})
	},
	listByClient(data) {
		return axios.get(`/sat/tags/list/client`, {params: data})
	},
}