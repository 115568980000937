import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/sat/users/find/${id}`, { params: data })
	},
	search(data) {
		return axios.get(`/sat/users/list`, { params: data })
	},
	create(data) {
		return axios.post(`/sat/users/create`, data)
	},
	update(data) {
		return axios.post(`/sat/users/update`, data)
	},
	delete(id) {
		return axios.delete(`/sat/users/delete/${id}`)
	},
	listAll(data) {
		return axios.get(`/sat/users/list/all`, { params: data })
	},
	createUserClient(data) {
		return axios.post(`/sat/users/client/create`, data)
	},
	listUsersClient(data) {
		return axios.get(`/sat/users/client/list`, { params: data})
	},
	updateUserClient(data) {
		return axios.post(`/sat/users/client/update`, data)
	},
	getUsersByPlatformAndWorkCenter(data) {
		return axios.get(`/sat/users/list/workcenter`, { params: data})
	},
}