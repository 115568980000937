import api from "@/router/api"

export default {
    searchTasks({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.tasks.search(payload)
                .then(response => {
                    const { data } = response.data
                    commit('SET_TASKS', data.data)
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener las tareas'
                    })
                })
        })
    },
    getTask({ commit }, payload) {

        return new Promise((resolve, reject) => {
            api.tasks.find(payload.id, payload)
                .then(response => {

                    commit('SET_TASK', response.data.data)

                    resolve(response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener la tarea',
                    })
                })
        })
    },
    createTask({ commit }, payload) {
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.tasks.create(payload)
                .then(response => {
                    const { data } = response.data
                    commit('SET_TASK', data)

                    resolve(data)
                })
                .catch(error => {

                    const { data, status } = error.response
                    const { message: title } = data

                    if (status === 422) {
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({ title, status })
                })
        })
    },
    updateTask({ commit }, payload) {
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.tasks.update(payload.id, payload)
                .then(response => {
                    const { data } = response.data
                    commit('SET_TASK', data)

                    resolve(data)
                })
                .catch(error => {
                    const { data, status } = error.response
                    const { message: title } = data

                    if (status === 422) {
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({ title, status })
                })
        })
    },
    deleteTask({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.tasks.delete(payload.id)
                .then(response => {

                    const { data } = response.data
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al borrar las tareas'
                    })
                })
        })
    },

    getAllTasks({ commit }, payload) {

        return new Promise((resolve, reject) => {

            api.tasks.getAllTasks(payload)
                .then(response => {

                    const { data } = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los usuarios.',
                    })
                })
        })
    },

}