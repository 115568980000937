import { TASKS_LIST, TASK_VIEW, TASK_CREATE, TASK_EDIT } from '../constants'

export default [{
        path: TASKS_LIST,
        name: 'tasks_list',
        component: () =>
            import ('@/views/tasks/list/listView.vue'),
        meta: {
            pageTitle: 'Listado de Tareas',
            permission: 'list_task',
            breadcrumb: [{
                text: 'Listado de Tareas',
                active: true,
            }, ],
        },
    },
    {
        path: `${TASK_VIEW}/:id`,
        name: 'tasks_view',
        component: () =>
            import ('@/views/tasks/sheet/sheetView.vue'),
        meta: {
            pageTitle: 'Ver Tarea',
            permission: 'see_task',
            breadcrumb: [{
                    text: 'Tareas',
                    to: TASKS_LIST,
                },
                {
                    text: 'Ver Tarea',
                    active: true,
                },
            ],
        },
    },
    {
        path: `${TASK_CREATE}`,
        name: 'tasks_create',
        component: () =>
            import ('@/views/tasks/form/formView.vue'),
        meta: {
            pageTitle: 'Crear Tarea',
            permission: 'create_task',
            breadcrumb: [{
                    text: 'Tareas',
                    to: TASKS_LIST,
                },
                {
                    text: 'Crear Tarea',
                    active: true,
                },
            ],
        },
    },
    {
        path: `${TASK_EDIT}/:id`,
        name: 'task_edit',
        component: () =>
            import ('@/views/tasks/form/formView.vue'),
        meta: {
            pageTitle: 'Editar Tarea',
            permission: 'update_task',
            breadcrumb: [{
                    text: 'Tareas',
                    to: TASKS_LIST,
                },
                {
                    text: 'Editar Tarea',
                    active: true,
                },
            ],
        },
    },

]