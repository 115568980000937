import state from './calendarState'
import actions from './calendarActions'
import mutations from './calendarMutations'
import getters from './calendarGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
