import api from "@/router/api"

export default {
    searchUserRegisterRequests({commit}, payload){
        return new Promise((resolve, reject) => {

            api.userRegisterRequest.search(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_USER_REGISTER_REQUESTS', data.data)
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener las peticiones de registro'
                    })
                })
        })
    },
    getUserRegisterRequest({commit}, payload){
        return new Promise((resolve, reject) => {
            api.userRegisterRequest.find(payload.id, payload)
                .then(response => {
                    commit('SET_USER_REGISTER_REQUEST', response.data.data)
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener la peticion de registro',
                    })
                })
        })
    },
}
