export const getDefaultState = () => {
    return {
        rmas: [],
        operators: [],

        rma: {
            id: null,
            description: null,
            address: null,
            postal_code: null,
            article_code: null,
            producer_code: null,
            model: null,
            serial: null,

            rma_id: null,
            wingest_id: null,
            work_center_id: null,
            client_id: null,
            rma_state_id: null,

            client: {
                name: null,
            },
            rma_state: {
                name: null,
            },
            operators: null,
        },

        validation: {
            operators: null,
        },

        search: {
            wingest_id: null,
            model: null,
            unassigned: false,
            date_start: null,
            date_end: null,
            description: null,
            client_id: null,
            rma_state_id: null,
            rma_states: [1]
        }
    }
}

export default getDefaultState()
