import state from './rmaState'
import actions from './rmaActions'
import mutations from './rmaMutations'
import getters from './rmaGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
