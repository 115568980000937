import axios from '@/resources/libs/axios'

export default {
	search(data) {
		return axios.get(`/sat/rmas/list`, {params: data})
	},
	find(id, data) {
		return axios.get(`/sat/rmas/find/${id}`, {params: data})
	},
	update(id, data) {
		return axios.post(`/sat/rmas/update/${id}`, data)
	},
	assignOperators(id, data) {
		return axios.post(`/sat/rmas/assignOperators/${id}`, data)
	},
	assignOperatorsRMAs(data) {
		return axios.post(`/sat/rmas/assignOperators/rmas`, data)
	},
	getCalendarRmas(data) {
		return axios.post('/sat/rmas/list/calendar', data)
	},
	deleteFile(id) {
		return axios.delete(`/sat/rmas/deleteFile/${id}`)
	},
	printPDF(data){
		return axios.post(`/sat/rmas/print/${data.id}`, data, {responseType: 'blob'})
	},
	getRmas(){
		return axios.get(`/wingest/get-rmas`)
	},
}