export const getDefaultState = () => {
    return {
        tags: [],
        tag: {
            id: null,
            code: null,
            client_id: null,
            equipment_id: null,
        },
        validation: {
            code: null,

            name: null,
            client_id: null,
        },
        search: {
            code: null,
            name: null,
            client_id: null,
        }
    }
}

export default getDefaultState()
