import axios from '@/resources/libs/axios'

export default {
    find(id, data) {
        return axios.get(`/sat/equipments/find/${id}`, {params: data})
    },
    search(data) {
        return axios.get(`/sat/equipments/list`, {params: data})
    },
    create(data) {
        return axios.post(`/sat/equipments/create`, data)
    },
    update(data) {
        return axios.post(`/sat/equipments/update`, data)
    },
    delete(id) {
        return axios.delete(`/sat/equipments/delete/${id}`)
    },
    listDocumentsInternalFolder(data) {
        return axios.get(`/sat/equipments/list/documents/internal/${data.id}`, {params: data})
    },
    saveDocumentsInternalFolder(data) {
        return axios.post('/sat/equipments/documents/internal/save', data)
    },
    createDocumentsInternalFolder(data) {
        return axios.post('/sat/equipments/documents/internal/create', data)
    },
    deleteDocumentsInternalFolder(data) {
        return axios.post('/sat/equipments/documents/internal/delete', data)
    },
    listDocumentsClientFolder(data) {
        return axios.get(`/sat/equipments/list/documents/client/${data.id}`, {params: data})
    },
    saveDocumentsClientFolder(data) {
        return axios.post('/sat/equipments/documents/client/save', data)
    },
    createDocumentsClientFolder(data) {
        return axios.post('/sat/equipments/documents/client/create', data)
    },
    deleteDocumentsClientFolder(data) {
        return axios.post('/sat/equipments/documents/client/delete', data)
    },
    getQrImage(id) {
        return axios.get(`/sat/equipments/get-qr-image/${id}`)
    },
}