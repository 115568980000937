export const getDefaultState = () => {
    return {
        events: [],

        search: {
            id: null,
            date_end: null,
            operators: null,
            client_id: null,
            ot_state_id: null,
        }
    }
}

export default getDefaultState()
