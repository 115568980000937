import {TAGS_LIST, TAGS_LIST_CLIENT, TAG_VIEW, TAG_CREATE, TAG_EDIT} from '../constants'

export default [
	{
		path: `${TAGS_LIST}`,
		name: 'tags_list',
		component: () => import('@/views/tags/list/listView.vue'),
		meta: {
			pageTitle: 'Listado tags',
			breadcrumb: [
				{
					text: 'Listado tags',
					active: true,
				},
			],
		},
	},
	{
		path: `${TAGS_LIST}/:id`,
		name: 'tags_list_client',
		component: () => import('@/views/tags/list/listView.vue'),
		meta: {
			pageTitle: 'Listado tags',
			breadcrumb: [
				{
					text: 'Listado tags',
					active: true,
				},
			],
		},
	},
	{
		path: `${TAG_VIEW}/:id`,
		name: 'tags_view',
		component: () => import('@/views/tags/form/formView.vue'),
		meta: {
			pageTitle: 'Ver tag',
			breadcrumb: [
				{
					text: 'Tags',
					to: TAGS_LIST,
				},
				{
					text: 'Ver tag',
					active: true,
				},
			],
		},
	},
	{
		path: `${TAG_CREATE}`,
		name: 'tag_create',
		component: () => import('@/views/tags/form/formView.vue'),
		meta: {
			pageTitle: 'Crear Tag',
			breadcrumb: [
				{
					text: 'Tags',
					to: TAGS_LIST,
				},
				{
					text: 'Crear Tag',
					active: true,
				},
			],
		},
	},
	{
		path: `${TAG_CREATE}/:client`,
		name: 'tag_create_client',
		component: () => import('@/views/tags/form/formView.vue'),
		meta: {
			pageTitle: 'Crear Tag de Cliente',
			breadcrumb: [
				{
					text: 'Tags',
					to: TAGS_LIST,
				},
				{
					text: 'Crear Tag',
					active: true,
				},
			],
		},
	},
	{
		path: `${TAG_EDIT}/:client/:id`,
		name: 'tag_edit',
		component: () => import('@/views/tags/form/formView.vue'),
		meta: {
			pageTitle: 'Editar Tag',
			breadcrumb: [
				{
					text: 'Editar Tag',
					active: true,
				},
			],
		},
	}
]
