import state from './roleState'
import actions from './roleActions'
import mutations from './roleMutations'
import getters from './roleGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
