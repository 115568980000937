import axios from '@/resources/libs/axios'

export default {
    login(email, password) {
        return axios.post('/sat/login', {email, password})
    },
    logout() {
        return axios.post('/sat/logout')
    },
    updateUserData(idUser) {
        return axios.post('/sat/infoUser', { idUser })
    },
}
