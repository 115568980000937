import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { isUserLoggedIn } from '@/services/auth/utils'
import { DASHBOARD, LOGIN } from './constants'
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import store from '@/store'

import pages from './modules/pages'
import roles from './modules/roles'
import users from './modules/users'
import clients from './modules/clients'
import equipments from './modules/equipments'
import tags from './modules/tags'
import usersClients from './modules/usersClients'
import interventions from './modules/interventions'
import userRegisterRequest from './modules/userRegisterRequests'
import rmas from './modules/rmas'
import workParts from './modules/workParts'
import ots from './modules/ots'
import tasks from './modules/tasks'
import calendar from "./modules/calendar"

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        { path: '/', redirect: { name: 'dashboard' } },
        {
            path: DASHBOARD,
            name: 'dashboard',
            component: () =>
                import ('@/views/dashboard/ecommerce/Ecommerce.vue'),
        },
        ...pages,
        ...roles,
        ...users,
        ...clients,
        ...equipments,
        ...tags,
        ...usersClients,
        ...interventions,
        ...userRegisterRequest,
        ...rmas,
        ...ots,
        ...tasks,
        ...workParts,
        ...calendar,
        {
            path: '*',
            redirect: 'error-404',
        },
        {
            path: `/sistema`,
            name: 'sistema',
            component: () => import('@/views/system.vue'),
            meta: {
                pageTitle: 'Sistema',
                permission: 'system',
                breadcrumb: [
                    {
                        text: 'Sistema',
                        active: true,
                        permission: 'system',
                    },
                ],
            },
        },
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    if (!isLoggedIn && to.fullPath != LOGIN) {
        return next({ name: 'auth-login' })
    }

    if (to.meta.permission) {
        if (!store.getters.can(to.meta.permission)) {
            Vue.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `No tienes permisos para acceder a esa página.`,
                    icon: 'XSquareIcon',
                    variant: 'danger',
                },
            })

            router.push({ path: DASHBOARD })
        }
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router