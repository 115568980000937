import api from "@/router/api"

export default {
    searchInterventions({commit}, payload){
        return new Promise((resolve, reject) => {
            api.interventions.search(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_INTERVENTIONS', data.data)
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener las intervenciones'
                    })
                })
        })
    },
    getIntervention({commit}, payload){
        return new Promise((resolve, reject) => {
            api.interventions.find(payload.id, payload)
                .then(response => {
                    commit('SET_INTERVENTION', response.data.data)

                    resolve(response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener la intervención',
                    })
                })
        })
    },
    getStates({commit}){
        return new Promise((resolve, reject) => {
            api.interventions.getStates()
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener la intervención',
                    })
                })
        })
    },
    update({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.interventions.update(payload)
                .then(response => {
                    const { data } = response.data
                    resolve(data)
                })
                .catch(error => {
                    const { data, status } = error.response
                    const { message: title } = data

                    reject({
                        message: 'Error al actualizar la intervencion'
                    })
                })
        })
    },
}
