import state from './paginationState'
import actions from './paginationActions'
import mutations from './paginationMutations'
import getters from './paginationGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
