import api from "@/router/api"

export default {
	getStates({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.otStates.getStates(payload)
				.then(response => {
					const { data } = response.data
					commit('SET_OTS', data.data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los ots'
					})
				})
		})
	},
}
