import state from './userState'
import actions from './userActions'
import mutations from './userMutations'
import getters from './userGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
