import {ALL_WORK_PARTS_VIEW} from '../constants'

export default [
    {
        path: ALL_WORK_PARTS_VIEW,
        name: 'workpart_list',
        component: () => import('@/views/workPart/listAll/listAllView.vue'),
        meta: {
            pageTitle: 'Listado Partes de trabajo',
            permission: 'list_workparts',
            breadcrumb: [
                {
                    text: 'Listado Partes de trabajo',
                    active: true,
                },
            ],
        },
    },
]
