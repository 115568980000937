import state from './otState'
import actions from './otActions'
import mutations from './otMutations'
import getters from './otGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
