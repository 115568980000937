import state from './userRegisterRequestState'
import actions from './userRegisterRequestActions'
import mutations from './userRegisterRequestMutations'
import getters from './userRegisterRequestGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
