import api from "@/router/api"

export default {
    searchRmas({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.rmas.search(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_RMAS', data.data)
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los rmas'
                    })
                })
        })
    },
    getRma({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.rmas.find(payload.id, payload)
                .then(response => {
                    commit('SET_RMA', response.data.data)

                    if (response.data.data.operators) {
                        commit('SET_RMA_OPERATORS', response.data.data.operators)
                        commit('SET_OPERATORS', response.data.data.operators)
                    }

                    resolve(response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener el rma',
                    })
                })
        })
    },
    getCalendarRmas({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.rmas.getCalendarRmas(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener el rma',
                    })
                })
        })
    },
    assignOperators({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.rmas.assignOperators(payload.id, payload)
                .then(response => {
                    commit('SET_RMA', response.data.data)
                    resolve(response.data.data)
                })
                .catch(error => {
                    const {data, status} = error.response
                    const {message: title} = data

                    if (status === 422) {
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }

                    reject({title, status})
                })
        })
    },
    deleteFile({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.rmas.deleteFile(payload.id)
                .then(response => {
                    const {data} = response.data
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al borrar el archivo'
                    })
                })
        })
    },
    printPDF({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.rmas.printPDF(payload)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    const {data: {message}} = error.response
                    reject({
                        message
                    })
                })
        })
    },
    updateRma({commit}, payload) {
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.rmas.update(payload.id, payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_RMA', data)

                    resolve(data)
                })
                .catch(error => {
                    const {data, status} = error.response
                    const {message: title} = data

                    if (status === 422) {
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
    assignOperatorsRMAs({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.rmas.assignOperatorsRMAs(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const {data, status} = error.response
                    const {message: title} = data

                    if (status === 422) {
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({title, status})
                })
        })
    },
}
