export const HOME = '/'
export const DASHBOARD = '/dashboard'

export const LOGIN = '/login'
export const REGISTER = '/register'
export const FORGOT_PASSWORD = '/forgot-password'
export const PAGE_404 = '/error-404'
export const NOT_AUTHORIZED = '/pages/miscellaneous/not-authorized'
export const UNDER_MAINTENANCE = '/pages/miscellaneous/under-maintenance'
export const ERROR = '/pages/miscellaneous/error'

export const USER_LIST = '/usuarios/listado'
export const USER_CREATE = '/usuarios/crear'
export const USER_EDIT = '/usuarios/editar'

// export const USERS_CLIENTS_EDIT = '/usuarios-clientes/editar'
export const USER_CLIENT_LIST_GMAO = '/usuarios/gmao/cliente/listado'
export const USER_CLIENT_LIST = '/usuarios/cliente/listado'
export const USER_CLIENT_CREATE = '/usuarios/cliente/crear'
export const USER_CLIENT_EDIT = '/usuarios/cliente/editar'
export const USER_CLIENT_SHEET = '/usuarios/cliente/ver'


export const ROLES_LIST = '/roles/listado'
export const ROLES_CREATE = '/roles/crear'
export const ROLES_EDIT = '/roles/editar'

export const CLIENTS_LIST = '/clientes/listado'
export const CLIENT_VIEW = '/clientes/ver'

export const EQUIPMENTS_LIST = '/equipos/listado'
export const EQUIPMENTS_CLIENTS_LIST = '/equipos/listado/cliente'
export const EQUIPMENTS_CREATE = '/equipos/crear'
export const EQUIPMENTS_VIEW = '/equipos/ver'
export const EQUIPMENTS_EDIT = '/equipos/editar'

export const TAGS_LIST = '/tags/listado'
export const TAGS_LIST_CLIENT = '/tags/listado/cliente'
export const TAG_VIEW = '/tags/ver'
export const TAG_CREATE = '/tags/crear'
export const TAG_EDIT = '/tags/editar'

export const LOGIN_USER_LOGS_LIST = '/accesos_usuario/list'

export const INTERVENTIONS_LIST = '/intervenciones/listado'
export const INTERVENTION_VIEW = '/intervenciones/ver'

export const USER_REGISTER_REQUESTS_LIST = '/peticiones-registro/listado'
export const USER_REGISTER_REQUESTS_SHEET = '/peticiones-registro/ver'

export const RMAS_LIST = '/rmas/listado'
export const RMA_VIEW = '/rmas/ver'

export const OTS_LIST = '/ots/listado'
export const OT_VIEW = '/ots/ver'
export const OT_CREATE = '/ots/crear'
export const OT_EDIT = '/ots/editar'
export const OTS_CONTROL = '/ots/control'

export const TASKS_LIST = '/tasks/listado'
export const TASK_VIEW = '/tasks/ver'
export const TASK_CREATE = '/tasks/crear'
export const TASK_EDIT = '/tasks/update'

export const CALENDAR = '/calendario'

export const OT_WORK_PART_CREATE = '/ots/parte-trabajo/crear'
export const OT_WORK_PART_EDIT = '/ots/parte-trabajo/editar'
export const OT_WORK_PART_LIST = '/ots/parte-trabajo/listar'
export const OT_WORK_PART_VIEW = '/ots/parte-trabajo/ver'

export const RMA_WORK_PART_CREATE = '/rmas/parte-trabajo/crear'
export const RMA_WORK_PART_EDIT = '/rmas/parte-trabajo/editar'
export const RMA_WORK_PART_LIST = '/rmas/parte-trabajo/listar'
export const RMA_WORK_PART_VIEW = '/rmas/parte-trabajo/ver'

export const ALL_WORK_PARTS_VIEW = '/partes-trabajo/listar'