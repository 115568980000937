export const getWorkPartDefaultState = () => {
    return {
        workPart: {
            id: null,
            type: null,

            tasks_performed: null,
            signed: null,
            ended: null,
            worked_hours: null,
            workpartable_id: null,
            firm: null,
            close_ot: null,
            workpart_type: null,

            users: [],
            tasks: [],
            workpartable: [],
            workProcess: [],
            workProcessFiles: [],

            work_part_time_events: [],
            work_part_time_last_event: []
        },
    }
}

export const getDefaultState = () => {
    return {
        workpartable: [],
        workParts: [],
        workPartsDocuments: [],
        listMaterials: [],

        process_1: [],
        process_2: [],
        process_3: [],

        see_form: false,
        load_workparts: false,
        set_timer: false,
        showModalMaterials: false,

        id_edit: null,

        page: 1,
        pageLength: 10,

        validation: {
            id: null,
            type: null,

            tasks_performed: null,
            signed: null,
            ended: null,
            worked_hours: null,
            workpartable_id: null,
            close_ot: null,
            workpart_type: null,

            users: null,
        },
    }
}

export default {...getDefaultState(), ...getWorkPartDefaultState() }