export const getDefaultState = () => {
    return {
        ots: [],
        ot: {
            id: null,
            client_id: null,
            ot_state_id: null,
            description: null,
            address: null,
            postal_code: null,
            article_code: null,
            producer_code: null,
            model: null,
            serial: null,
        },
        validation: {
            client_id: null,
            operators: null,
            ot_state_id: null,
            description: null,
            address: null,
            postal_code: null,
            article_code: null,
            producer_code: null,
            model: null,
            serial: null,
        },
        search: {}
    }
}

export default getDefaultState()
