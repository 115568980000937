const getters = {
	can: state => permission => {
		return state.AppActiveUser.permissions.indexOf(permission) !== -1
	},
	department: state => {
		return state.AppActiveUser.departments.map(function(department) {
			return department.name
		})
	},
	//Comprobar si existe
	checkCenter: state => departments =>  {
		let departmentsUsers = state.AppActiveUser.departments.map(function(department) {
			return department.name
		})

		return departmentsUsers.some(r=> departments.includes(r))
	},
	type: state => {
		return state.AppActiveUser.roles.map(function(role) {
			return role.type
		})
	}
}

export default getters